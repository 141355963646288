import { ComponentRef, Injectable } from '@angular/core';
import { LogoComponent } from 'src/app/shared/components/globals/logo/logo.component';
import { PopoverService } from '../../popover.service';
import { MapViewerNode } from '@3ddv/dvm-internal';
import { ComputePositionReturn, detectOverflow, Middleware, MiddlewareReturn, MiddlewareState, Placement, SideObject } from '@floating-ui/core';
import { ComputePositionConfig } from '@floating-ui/dom';
import { Observable, tap } from 'rxjs';
import { PopoverInstanceService } from './popover-instance.service';
import { DvmPopoverComponent } from 'src/app/shared/components/globals/popover/partials/dvm-popover/dvm-popover.component';

export class DvmPopoverService extends PopoverService {

    constructor() { super() }

    public override time:   number = 150;
    
    public override createPopover(
        elementReference:   HTMLElement | MapViewerNode,
        cssClass?:          string | string[],
        options?:           Partial<ComputePositionConfig> | undefined
    ):  Observable<ComputePositionReturn> {

        // Eliminamos el Popover anterior
        this.destroyPopover(elementReference.id);
       
        // Configuración del Popover custom DVM
        const popoverOptions: ComputePositionConfig = options ?? this.getPopoverConfig();

        // Aplicamos Overflow Middleware
        popoverOptions.middleware?.push(this.createOverflowMiddleware() as Middleware);

        // Calculamos la posición del Popover
        popoverOptions.placement = this.calculatePlacement(elementReference as MapViewerNode);
        
        
        return super.createPopover(elementReference, cssClass ?? 'popover-dvm', popoverOptions).pipe(
            tap((): void => {

                this.popoverId = elementReference.id;

                this.setComponent(DvmPopoverComponent);

                this.component.setInput('mapNode', elementReference);

            }),
        );

    }

    protected override calculatePlacement(node: MapViewerNode): Placement {
        if (node.anchor.dom![1] > (this.boundary!.offsetHeight / 2)) {
            return 'top';
        } else {
            return'bottom';
        }
    }

}