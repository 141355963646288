import { Injectable, inject, WritableSignal, Signal, signal, computed } from '@angular/core';
import { Observable, tap, lastValueFrom, take } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http'
import { TdcInfo, User } from 'src/app/shared/models/user.model';
import { LOADING_IGNORED } from '../interceptors/api-url.interceptor';
import { UpdateUserData } from 'src/app/shared/models/update.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Service and Endpoint
  private  http:      HttpClient = inject(HttpClient);
  private  endpoint:  string     = '/auth';
  
  // Private Signals
  private  user:      WritableSignal<User|undefined>  = signal(undefined);
  private  token:     WritableSignal<string|null>     = signal(null);

  // Readonly Signals
  public   userData:  Signal<User>    = computed(()=> this.user() as User);
  public   tokenData: Signal<string>  = computed(()=> this.token() as string)
  
  
  //Login and Logout 
  /**
   * Formatea el user y el password como un objeto y retorna un observable para subscribirse y realizar la petición.
   * @param {string} user 
   * @param password 
   * @returns 
   */
  public login(user: User['email'], password: string ): Observable<User> {
   
    const data = {
      username: user,
      password: password
    }

    return this.http.post<User>(this.endpoint + '/login/', data).pipe(take(1), tap(value => this.setUser(value as User))) as Observable<any>;

  }

  /**
   * Reestablece el estado de User y del Token. Retorna un observable que al subscribirse llama al endpoint de logout. 
   * Al hacerlo, el user estará desautenticado y los guards le navegarán al login. 
   */
  public logout(): Observable<void> {
    this.user.set(undefined);
    this.token.set(null);
    return this.http.get<void>(this.endpoint + '/logout/', {
      context: new HttpContext().set(LOADING_IGNORED, true)
    });
  }

  //Reset and Set New Password
  /**
   * Función que inicia el procedimiento para resetear el password. 
   * Formatea el objeto body con el email y retorna una solicitud post que al subscribirse,
   * inicia la solicitud y por la cual si existe un usuario con ese correo, llegará un email para iniciar el 
   * cambio de contraseña.
   * @param email 
   * @returns 
   */
  public resetPassword(email: User['email']): Observable<string> {
    const body: any = { email };
    return this.http.post(
      this.endpoint + '/password-reset/',
      body,
      {responseType: 'text'}
    )
  }

  /**
   * Función con la cual guardamos y reestablecemos el nuevo password. 
   * Está función toma como parámetro el token con el que el usuario viene informado 
   * del correo electrónico enviado a partir del método resetPassword, y el password nuevo introducido
   * por el usuario.
   * @param token 
   * @param password 
   * @returns 
   */
  public saveNewPassword(token:string, password:string): Observable<string> {
    const body: any = {token, password};
    return this.http.post(
      this.endpoint + '/password-reset/finalize/',
      body,
      {responseType: 'text'}
    )
  }

  /**
   * Método que actualiza los datos de usuario.
   * Se le pasa un objeto con los datos a actualizar y un key que puede ser 'user' o 'dpu' para actualizar los datos de usuario o los de protección de datos.
   * @param { UpdateUserData | [x: string]: TdcInfo['data_protection'][0] } value 
   * @param { string } key 
   * @returns { Observable<void> }
   */
  public updateUserData(value: UpdateUserData | {[x: string]: TdcInfo['data_protection'][0]}, key: 'user' | 'dpu' = 'user'): Observable<void> {
    
    const endpoint: string = '/customer' + (key === 'user' ? '/update/' : '/update/dpu/');

    return this.http.put<void>(endpoint, value)

  }

  // Get User Data 
  /**
   * Función que retorna una promesa asíncrona. Esta promesa va a comprobar si existen datos de usuario. 
   * Si existen, devuelve los datos, si no existe, llama a la función lastValueFrom de getUserLogged, la cual lanzará una petición a la API
   * para saber si habia un user logueado. Si habia, la misma función de getUserLogged seteará los datos del user y podemos devolver user, si devuelve -1
   * es que no hay user y por lo tanto devuelve false. 
   * @returns User | False
   */
  public getUser(force?:boolean): Promise<User | false> {
    
    return new Promise<User | false>(async (resolve) => {
      
      if(this.user() === undefined || force){

       await lastValueFrom(this.getUserLogged())
       .then(user => user?.tdc_info.id === -1 ? resolve(false) : resolve(user))
       .catch(error => resolve(false))

      }else{

        resolve(this.user() as User);

      }

    })

  }

  // Get and Set User Data (Private Methods)
  /**
   * Realiza una petición a la Api, al endpoint Logged, el cual verifica si existia un user logueado y si lo hay, setea los datos devueltos.
   * @returns Observable<User>
   */
  private getUserLogged(): Observable<User> {
    return this.http.get<User>(this.endpoint + '/logged/').pipe(tap(value => this.setUser(value)))
  }

  /**
   * Función que mapea la respuesta de getUserLogged y setea en caso de haberlo el user y el token. 
   * @param {User} value 
   */
  private setUser(value: User): void {
    let user: User | undefined = value;
    
    if(user.csrf){
      this.token.set(user.csrf); 
    }

    if(user.tdc_info.id === -1){
      user = undefined;
    }
    
    this.user.set(user) 
  }

}
