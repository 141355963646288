<section>

    <ng-container *ngIf="userData || type === 'register'">
        
        <!-- FORM -->
        <app-personal-data-form
            #personal
            [userData]="userData"
            (updateData)="updateData($event)"
        />
    
        <!-- ACCOUNT SETTINGS -->
        <app-personal-data-account
            #account
            [userEmail]="userEmail"
            (updateEmail)="updateData($event)"
            (updatePassword)="updatePassword($event)"
        />
    
        <!-- DATA PROTECTION -->
        <app-personal-data-protection
            #protection
            [dataProtection]="userDataProtection"
            (updateDataProtection)="updateData($event, true)"
        />
        
    </ng-container>

</section>