import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { TdcInfo, User } from 'src/app/shared/models/user.model';
import { PersonalDataFormComponent } from './personal-data-form/personal-data-form.component';
import { PersonalDataAccountComponent } from './personal-data-account/personal-data-account.component';
import { PersonalDataProtectionComponent } from './personal-data-protection/personal-data-protection.component';
import { DataProtectionModel } from './personal-data-protection/personal-data-protection.model';
import { PersonalDataModel } from './personal-data-form/Personal-data.model';
import { PasswordDataModel } from './personal-data-account/password-data.model';
import { UpdateUserData } from 'src/app/shared/models/update.model';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Configuration } from 'src/app/shared/models/configuration.model';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.css'
})
export class PersonalDataComponent implements OnInit {

  // VIEW CHILDREN
  @ViewChild('personal')
  personal!:    PersonalDataFormComponent;

  @ViewChild('account')
  account!:     PersonalDataAccountComponent;

  @ViewChild('protection')
  protection!:  PersonalDataProtectionComponent;

  // SERVICES
  private auth:   AuthService    = inject(AuthService);
  private app:    Configuration  = inject(APP_CONFIG)
  
  // STATE
  public userData!: TdcInfo | undefined;
  protected user!:  User | undefined;
  protected type!:  'register' | 'update';
  
  
  // LIFE CYCLE HOOK
  ngOnInit(): void {
    this.initComponent();
  }

  // GETTERS
  public get allFormsAreValid(): boolean {
    
    if(!this.personal || !this.account || !this.protection){ 
      return false;
    }
    
    return this.personal.isFormValid && this.account.isFormValid && this.protection.isFormValid

  }

  public get userEmail(): TdcInfo['email'] | undefined {
    return this.userData?.email;
  }

  public get userDataProtection(): TdcInfo['data_protection'][0] | undefined {
    return this.userData?.data_protection[0];
  }

  // METHODS
  public updateData(value: any, dataProtection: boolean = false): void {
    
    if(!this.user){
      return;
    }

    return dataProtection ? 
      this.handleDataProtectionUpdate(value) :
      this.handleUserDataUpdate(value);

  };

  public updatePassword(value: any): void {
    console.log(value)
  };

  private handleUserDataUpdate(value: PersonalDataModel['values'] | PasswordDataModel['account']['values']): void {

    const personalData: PersonalDataModel['values'] | undefined             = value.hasOwnProperty('firstName') ? value as PersonalDataModel['values'] : undefined,
          emailData:    PasswordDataModel['account']['values'] | undefined  = value.hasOwnProperty('email') ? value as PasswordDataModel['account']['values'] : undefined;

    const updateData:   UpdateUserData = {
      first_name:                  personalData?.firstName ?? this.user?.tdc_info.first_name!,
      last_name:                   personalData?.lastName  ?? this.user?.tdc_info.last_name!,
      birthday:                    personalData?.birthDate ?? this.user?.tdc_info.birthday!,
      gender:                      personalData?.gender    ?? this.user?.tdc_info.gender!,
      email:      {
        email_id:                  this.user?.tdc_info.email.email_id,
        email_address:             emailData?.email ?? this.user?.tdc_info.email.email_address!
      },
      phone:      {
        phone_country_code:        personalData?.country     ?? this.user?.tdc_info.phone.phone_country_code!,
        number:                    personalData?.phoneNumber ?? this.user?.tdc_info.phone.number!
      },
      address:    {
        address_id:                this.user?.tdc_info.address.address_id,
        address1:                  personalData?.address  ?? this.user?.tdc_info.address.address1!,
        address2:                  personalData?.address2 ?? this.user?.tdc_info.address.address2!,
        address_country_code:      personalData?.country  ?? this.user?.tdc_info.address.address_country_code!,
        address_sub_country_code:  personalData?.country  ?? this.user?.tdc_info.address.address_sub_country_code!,
        address_sub_country_name:  personalData?.state    ?? this.user?.tdc_info.address.address_sub_country_name!,
        city:                      personalData?.city     ?? this.user?.tdc_info.address.city!,
        postal_code:               personalData?.zipCode  ?? this.user?.tdc_info.address.postal_code!,
        sub_country_name:          personalData?.city     ?? this.user?.tdc_info.address.address_sub_country_name!
      }

    }
    
    this.auth.updateUserData(updateData).subscribe({
      next: (response) => {
        this.auth.getUser(true).then((response: User | false ) =>  response ? this.userData = response.tdc_info : null);
      }
    })

  }
  
  private handleDataProtectionUpdate(value: DataProtectionModel['values']): void {
    const clientKey  = this.app.general.clientName.toUpperCase().replace(' ', '_'),
          updateData: { [x:string]: TdcInfo['data_protection'][0]} = {
            [clientKey]: {
              email_channel: value.email,
              mail_channel:  value.mail,
              phone_channel: value.phone,
              sms_text_channel: value.sms,
            }
          }

    this.auth.updateUserData(updateData, 'dpu').subscribe({
      next: (response) => {
        this.auth.getUser(true).then((response: User | false ) => response ? this.userData = response.tdc_info : null);
      }
    });
  }

  private initComponent(): void {
    this.auth.getUser().then((response: User | false ) => {
      if(response){
        this.user = response;
        this.type = 'update';
        this.userData = this.user.tdc_info;
      }else{
        this.type = 'register'
      }
    });
  }

}
