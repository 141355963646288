import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";


export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const password      = control.get('password'),
              new_password  = control.get('new_password');

        return ((password && new_password ) && password.value === new_password.value) ? null : { invalidPassword: control.value };
    };
}
