import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject, isDevMode } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Configuration } from './shared/models/configuration.model';
import { ModalService } from './core/services/modal.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { IdleService } from './core/services/idle.service';
import { AuthService } from './core/services/auth.service';
import { Idle } from './shared/models/idle.model';
import { PopoverComponent } from './shared/components/globals/popover/popover.component';
import { PopoverInstanceService } from './core/services/override/popovers/popover-instance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit , AfterViewInit {

  // Core Services
  private   configuration:    Configuration  = inject(APP_CONFIG);
  private   meta:             Meta           = inject(Meta);
  private   idle:             IdleService    = inject(IdleService);
  private   auth:             AuthService    = inject(AuthService);

  // Alerts and Modals
  protected modalService:     ModalService           = inject(ModalService);
  protected popoverInstance:  PopoverInstanceService = inject(PopoverInstanceService);

  @ViewChild('modal')
  modal!: SwalComponent 

  @ViewChild('popover')
  popover!: PopoverComponent;

  @ViewChild('popover', { read: ElementRef })
  popoverElement!: ElementRef;

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit(): void {
    this.initView();
  }

  private initComponent(): void {
    this.addMetaTags()
    this.initIdleService();
  }

  private initView(): void {
    this.initModalService();
    this.initPopoverService();
  }

  /**	INIT COMPONENT  */
  /**
   * Añade las etiquetas meta al documento en base a los datos de configuración.
   */
  private addMetaTags(): void {
    this.meta.addTags(this.configuration.metadata);
  }

  /**
   * Inicializa el servicio de inactividad. ( Solo en producción y preproducción )
   * 
   * Si se cumple el tiempo de inactividad, se desloguea al usuario y se redirige a la página de inicio. 
   */
  private initIdleService(): void {

    const configuration: Idle = this.configuration.idle;

    if(!configuration.active || isDevMode()){
      return;
    }
    
    if(!this.idle.isActive){
      this.idle.start(configuration.options);
    }

    this.idle.onTimeout().subscribe(()=> {
      this.auth.logout().subscribe(()=> location.replace(configuration.options.url));
    })

  }

  /** INIT VIEW */
  /**
   * Inicializa el servicio de modales y setea el modal por defecto.
   */
  private initModalService(): void {
    this.modalService.setModal(this.modal);
  }

  /**
   * Inicializa el servicio de Popover.
   */
  private initPopoverService(): void {
    this.popoverInstance.initPopover(this.popover, this.popoverElement);
  }
  
}

