import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { PersonalDataModule } from '../personal-data/personal-data.module';
import { FooterModule } from 'src/app/shared/components/globals/footer/footer.module';
import { LogoModule } from 'src/app/shared/components/globals/logo/logo.module';



@NgModule({
  declarations: [
    RegisterComponent
  ],
  imports: [
    CommonModule,
    LogoModule,
    PersonalDataModule,
    FooterModule,
  ]
})
export class RegisterModule { }
