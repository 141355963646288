<div class="px-3 py-3 lg:py-5 lg:px-0 bg-main/5">
    
    <app-form
        [title]="title"
        [form]="currentForm"
        [showButtons]="currentFormType !== 'new'"
        [hideEdit]="resetPassword"
        (submit)="submitForm(currentFormType)"
        (dismiss)="dismissChanges(currentFormType)"
        #formComponent
    >
        <!-- EMAIL , PASSWORD RESET , NEW ACCOUNT -->
        <div form>
            
            <!-- EMAIL & RESET PASSWORD -->
            <ng-container *ngIf="userEmail else newAccount">
                
                <!-- EMAIL ACCOUNT -->
                <ng-container *ngIf="!resetPassword else passwordReset">

                    <div class="flex md:items-end gap-x-2 lg:gap-x-4 md:mb-16">
                    
                        <form [formGroup]="accountEmailForm" id="account" class="form flex flex-col w-full mx-2 md:mx-0 pb-5 md:pb-0 md:max-w-sm">
                            
                            <label class="text-main font-semibold font-main mb-1" for="email">
                                Account Email
                            </label>
                            <input type="email" formControlName="email" id="email" class="text-darkgray" />

                            <span (click)="toggleResetPassword()" role="button" class="block ml-auto font-main font-semibold text-xs my-2 text-main transition-transform hover:scale-95 md:hidden">
                                Password Reset
                            </span>

                        </form>

                        <button (click)="toggleResetPassword()" class="button hidden md:block w-1/5 h-3/4">
                            Password Reset
                        </button>
                    </div>

                </ng-container>

                <!-- RESET PASSWORD -->
                <ng-template #passwordReset>
                    
                    <div class="flex md:items-end gap-x-2 lg:gap-x-4 md:mb-16 md:max-w-5xl">
                    
                        <form [formGroup]="resetPasswordForm" id="reset" class="form flex flex-col md:flex-row w-full mx-2 pb-5 gap-y-4 md:mx-0 md:pb-0 md:gap-3 md:w-2/3">
                            
                            <div class="flex flex-col md:w-1/2">
                                <label class="text-main font-semibold font-main mb-1" for="password">
                                    New Password
                                </label>
                                <input type="password" formControlName="password" id="password" />
                            </div>

                            <div class="flex flex-col md:w-1/2">
                                <label class="text-main font-semibold font-main mb-1" for="new_password">
                                    Re Type Password
                                </label>
                                <input type="password" formControlName="new_password" id="new_password"/>
                                <span (click)="toggleResetPassword()" role="button" class="block ml-auto font-main font-semibold text-xs my-2 text-main transition-transform hover:scale-95 md:hidden">
                                    Go Back
                                </span>
                            </div>
                            
                        </form>

                        <button (click)="toggleResetPassword()" class="button hidden md:block w-1/6 h-3/4">
                            Back
                        </button>
                    </div>

                </ng-template>

            </ng-container>
            
            <!-- NEW ACCOUNT -->
            <ng-template #newAccount> 
                
                <div class="flex md:items-end gap-x-2 lg:gap-x-4 md:mb-16 md:max-w-5xl">

                    <form [formGroup]="newAccountForm" class="form flex flex-col md:flex-row w-full mx-2 pb-5 gap-y-4 md:mx-0 md:pb-0 md:gap-3">
                        
                        <div class="flex flex-col md:w-1/2">
                            <label class="text-main font-semibold font-main mb-1" for="email">
                                Account Email
                            </label>
                            <input type="email" formControlName="email" id="email" />
                        </div>

                        <div class="flex flex-col md:w-1/2">
                            <label class="text-main font-semibold font-main mb-1" for="password">
                                Password
                            </label>
                            <input type="password" formControlName="password" id="password" />
                        </div>

                        <div class="flex flex-col md:w-1/2">
                            <label class="text-main font-semibold font-main mb-1" for="new_password">
                                Re Type Password
                            </label>
                            <input type="password" formControlName="new_password" id="new_password" />
                        </div>
                        
                    </form>

                </div>

            </ng-template>

        </div>

    </app-form>
    
</div>