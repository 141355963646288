import { Component, Host, inject, Input, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { PopoverService } from 'src/app/core/services/popover.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrl: './popover.component.css',
})
export class PopoverComponent {

  @ViewChild('content', {read: ViewContainerRef})
  content!: ViewContainerRef;

  @Input({required: false}) 
  cssClass!: string | string[] | undefined;

  private service!: PopoverService;

  protected get cssClasses(): string[] {
    const defaultClass: string = 'popover';
    return Array.isArray(this.cssClass) ?
     [defaultClass, ...this.cssClass] : 
     [defaultClass, this.cssClass ?? ''];
  }

  public setService(service: PopoverService): void {
    this.service = service;
  }

  public stopTimer(): void {    
    this.service.stopTimer();    
  }

  public startTimer(): void {
    this.service.startTimer();
  }

}
