import { ComponentRef, ElementRef, Injectable, signal, WritableSignal } from '@angular/core';
import { PopoverComponent } from 'src/app/shared/components/globals/popover/popover.component';

@Injectable({
    providedIn: 'root'
})
export class PopoverInstanceService {

    // POPOVER HTML, INSTANCE AND ARROW
    private _popover!:           HTMLElement | null;
    private _popoverArrow!:      HTMLElement | null;
    private _popoverComponent!:  PopoverComponent | null;

    // GETTERS
    public get popover(): HTMLElement | null {
        return this._popover;
    }

    public get popoverArrow(): HTMLElement | null {
        return this._popoverArrow;
    }

    public get popoverComponent(): PopoverComponent | null {
        return this._popoverComponent ?? null;
    }

    // SETTERS
    public initPopover(popoverInstace: PopoverComponent, popoverElement: ElementRef): void | Error {

        if( this.popover && this.popoverComponent ){
            throw new Error('Popover already initialized');
        }else if(!popoverInstace || !popoverElement){
            throw new Error('Popover element not found');
        }
      
        this.initPopoverService(popoverInstace, popoverElement);

    }

    private initPopoverService(popoverComponent: PopoverComponent, popoverElement: ElementRef): void | Error {

        const popoverInstance: PopoverComponent = popoverComponent,
              popoverTemplate: HTMLElement      = popoverElement.nativeElement.querySelector('.popover') as HTMLElement,
              popoverArrow:    HTMLElement      = popoverElement.nativeElement.querySelector('#arrow') as HTMLElement;

        this._popover           = popoverTemplate;
        this._popoverArrow      = popoverArrow;
        this._popoverComponent  = popoverInstance;
        
    }
    
}