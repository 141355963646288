<section class="bg-lightgray/50 relative ">

    <div class="flex w-full bg-white p-5 gap-x-2 justify-center items-center lg:absolute lg:p-3 lg:block lg:bg-transparent">
        <div class="w-8 lg:hidden">
            <app-logo />
        </div>
        <h1 class="font-main font-medium text-darkgray">
            Chelsea Fc 
            <span class="text-darkgray/50">
                | Official Ticketing Portal
            </span>
        </h1>
    </div>

        <!-- TITLE -->
    <div class="flex flex-col items-center p-4 container mx-auto w-full py-10 lg:pt-20">
        
        <!-- TITLE & HEADER -->
        <h2 class="text-darkgray dark:text-lightgray/50 font-medium text-2xl mb-1 lg:font-bold lg:text-3xl">
            New Account
        </h2>

        <h3 class="text-secondary dark:text-lightgray/60 font-normal text-center lg:mb-4 text-xs md:text-base lg:text-xs">
            Please ensure your address details (including state & postal code) are correct before making your purchase.
        </h3>

    </div>

    <!-- FORM -->
    <div class="bg-white pt-5 mb-20">
        <app-personal-data #registerForm />
    </div>

    <app-footer [showFooter]="true" [buttonText]="'Register'" [disableButton]="!isFormValid" (footerEvent)="startRegister()"/>
</section>