import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RegisterData } from 'src/app/shared/models/register.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private http:       HttpClient  = inject(HttpClient);
  private endpoint:   string      = '/customer/';

  /**
   * Función que inicia el procedimiento de registro de un usuario.
   * @param {string} email 
   * @returns Observable<string>
   */
  public registerNewAccount(email: string): Observable<string> {
    const endpoint: string = this.endpoint + 'register/',
          body: { email: string } = { email };
    
    return this.http.post<string>( endpoint, body, {responseType: 'json'})
  }

  public checkRegisterToken(token: string): Observable<RegisterData['dpu']> {
    const endpoint: string = this.endpoint + 'register/new-account/';
    
    return this.http.post<any>(endpoint, { token: token });
  }

  public finalizeRegister(token: string, accountData: RegisterData['user']): Observable<any>{
    const endpoint: string = this.endpoint + 'register/new-account/finalize/',
          body: {contact_data: RegisterData['user'], token: string} = {
            contact_data: accountData,
            token: token
          } 

    return this.http.post<any>(endpoint, body);
  }

}
