import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { PersonalDataComponent } from '../personal-data/personal-data.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { PasswordDataModel } from '../personal-data/personal-data-account/password-data.model';
import { RegisterData } from 'src/app/shared/models/register.model';
import { RegisterService } from 'src/app/core/services/register.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent implements OnInit {

  // VIEW CHILDS
  @ViewChild('registerForm')
  registerForm!: PersonalDataComponent;

  // SERVICES
  private router:       Router          = inject(Router);
  private register:     RegisterService = inject(RegisterService);
  private modalService: ModalService    = inject(ModalService);

  // STATE
  private token:   string | null        = inject(ActivatedRoute).snapshot.queryParams['t'] ?? null;
  private dpuId!:  number | null;       // El código id de DPUs que se usará en el objeto de Registro. 

  // GETTERS
  public get isFormValid(): boolean {
    return this.registerForm ? 
      this.registerForm.allFormsAreValid :
      false;
  }

  // LIFECYCLE HOOKS
  public ngOnInit(): void {
    this.startComponent();
  }

  // METHODS
  public startRegister(): any {
    
    const forms                       = this.registerForm,
          personalData                = forms.personal.form.value,
          accountData                 = forms.account.currentForm.getRawValue() as PasswordDataModel['new']['values'],
          protectionData              = forms.protection.dataProtectionForm.value,
          registerData: RegisterData['user']  = {
            first_name: personalData.firstName!,
            last_name:  personalData.lastName!,
            gender:     personalData.gender!,
            birthday:   personalData.birthDate!,
            password:   accountData.password!,
            phone:      {
              number:             personalData.phoneNumber!,
              phone_country_code: personalData.country!
            },
            email:      {
              email_address: accountData.email!,
            },
            address:     {
              address1:     personalData.address!,
              address2:     personalData.address2 ?? '',
              city:         personalData.city!,
              postal_code:  personalData.zipCode!,
              address_country_code:     personalData.country!,
              address_sub_country_code: personalData.state ?? '',
              address_sub_country_name: personalData.state ?? '',
            },
            dataprotection: [
              { 
                id:               this.dpuId!,
                email_channel:    protectionData.email!,
                mail_channel:     protectionData.mail!,
                phone_channel:    protectionData.phone!,
                sms_text_channel: protectionData.sms!
              }
            ] 
          };

    this.register.finalizeRegister(this.token!, registerData).subscribe({
      next: ()=> {
        this.router.navigate(['/login']);
      },
      error: (error: HttpErrorResponse)=> {
        this.handleError(error);
      }
    })
  
  }
  
  // PRIVATE METHODS

  /**
   * Inicializa el componente. 
   * Verifica que exista token y que este sea válido. Una vez realizado, setea el form con los datos de protección y email.
   * @returns 
   */
  private startComponent(): void {
    
    // Si no hay token, mostramos modal y redirigimos a login.
    if(!this.token) {
      this.handleError();
      return;
    }

    // Si hay token, comprobamos que sea válido y seteamos el form con el email y los Dpus. 
    this.register.checkRegisterToken(this.token).subscribe({
      next:  (response: RegisterData['dpu']) => this.populateForm(response),
      error: (error: HttpErrorResponse) => this.handleError(error),
    });

  }

  /**
   * Lanza un modal de error y redirige a login.
   * Si no tiene error custom, muestra el texto hardcodeado de que no se ha proporcionado token.
   * @param error 
   */
  private handleError(error?: HttpErrorResponse): void {

    this.modalService.createErrorModal({
      content: error?.error.message ?? 'No token was provided, please check your email and try again.', 
      onConfirm: ()=> this.router.navigate(['/login'])
    });

  }
  
  /**
   * Método llamado tras la respuesta de checkRegisterToken.
   * Setea el email en el form el email y los data protection units, deshabilitando el propio campo de email 
   * y los campos data protection que sean null. 
   * @param dataProtectionUnits 
   */
  private populateForm(dataProtection: RegisterData['dpu']): void {
    
    // Seteamos el email en el form y deshabilitamos el campo.
    const emailForm: FormGroup<PasswordDataModel['new']['form']> = this.registerForm.account.currentForm as FormGroup<PasswordDataModel['new']['form']>;

    /*
     * Se usa el email con el que ha comenzado el registro y se deshabilita el campo.
     * Utilizamos onlySelf y emitEvent porque usando disable sin parametros, no solo 
     * lo deshabilita sino que no pasa el valor al form.
    */
    if(dataProtection.email){
      emailForm.controls.email.setValue(dataProtection.email);
      emailForm.controls.email.disable({onlySelf: true, emitEvent: true});
    }

    // Seteamos los data protection units en el form.
    const dataProtectionForm = this.registerForm.protection.dataProtectionForm,
          dataProtectionUnit = dataProtection.data_protection_units[0];

    // Seteamos el ID del DPU para la petición de registro.
    this.dpuId = dataProtectionUnit.id;

    dataProtectionForm.setValue({
      email:  dataProtectionUnit.emailSetting,
      mail:   dataProtectionUnit.mailSetting,
      phone:  dataProtectionUnit.phoneSetting,
      sms:    dataProtectionUnit.smsSetting
    })

    // Validamos si alguno de los campos es null y lo deshabilitamos.
    Object.values(dataProtectionForm.controls).forEach((control: FormControl) => control.value === null ? control.disable() : null);

  }

}
