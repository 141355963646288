<div class="px-3 lg:py-5 lg:px-0 xl:max-w-6xl mx-auto">

   <app-form
    [title]="templateVars.title"
    [form]="dataProtectionForm"
    [showButtons]="dataProtection ? true : false"
    (submit)="submitForm()"
    (dismiss)="dismissChanges()"
   >

    <div form>
        
        <!-- TEXT 1 -->
        <p class="text-main" [innerHTML]="templateVars.text1"></p>

        <!-- FORM -->
        <form 
            [formGroup]="dataProtectionForm" 
            class="my-5 grid grid-cols-2 gap-y-2 align-middle md:flex md:max-w-xl md:justify-between"
        >

        <div 
                *ngFor="let contactMethods of dataProtectionForm.controls | keyvalue"
                class="flex flex-row-reverse justify-center" 
                [ngClass]="{'hidden': dataProtectionForm.controls[contactMethods.key].disabled && dataProtection === undefined}"
            >
                
                <label 
                    [for]="contactMethods.key" 
                    class="ml-3 w-2/4 font-main"
                >
                    {{contactMethods.key | titlecase}}
                </label>

                <input 
                    [formControlName]="contactMethods.key" 
                    [id]="'data_'+ contactMethods.key" 
                    [value]="contactMethods.value.value"
                    type="checkbox" 
                    class="radio" 
                >
                
            </div>
        
        </form>

        <!-- TEXT 2 -->
        <p class="text-main" [innerHTML]="templateVars.text2"></p>
        
    </div>

   </app-form>

</div>