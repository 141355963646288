import { MapViewerNode } from '@3ddv/dvm-internal';
import { ComponentRef, inject } from '@angular/core';
import { arrow, computePosition, ComputePositionConfig, ComputePositionReturn, detectOverflow, flip, Middleware, MiddlewareReturn, MiddlewareState, offset, Placement } from '@floating-ui/dom';
import { from, Observable, tap } from 'rxjs';
import { PopoverInstanceService } from './override/popovers/popover-instance.service';

export abstract class PopoverService {

  // POPOVER INSTANCE
  private readonly popoverInstance: PopoverInstanceService = inject(PopoverInstanceService);

  // Configuración variable 
  public boundary:      HTMLElement | null = null;
  public popoverId:          string | null = null;
  public time:                number       = 100;

  // Timer 
  public timer:         ReturnType<typeof setTimeout> | null = null;

  // Componente dinámico
  public component!:    ComponentRef<any>;

  // PUBLIC METHODS

  /**
   * Método base que llama al método privado createNewPopover.
   * Este método se encarga de crear un Popover y devolver un Observable con la posición del Popover.
   * Usado principalmente en las especializaciones de este servicio.
   * @param elementReference 
   * @param cssClass 
   * @param options 
   * @returns 
   */
  public createPopover( 
    elementReference: HTMLElement | MapViewerNode, 
    cssClass?: string | string[],
    options?: Partial<ComputePositionConfig> 
  ): Observable<ComputePositionReturn> {

    return this.createNewPopover(elementReference, cssClass, options);
  }

  /**
   * Retorna el objeto de configuración del Popover.
   * Mediante este método, podemos obtener la configuracion usada y modificarla, extenderla o sobreescribirla.
   * @returns {ComputePositionConfig} Configuración del Popover
   */
  public getPopoverConfig(): ComputePositionConfig {
    return Object.assign({}, this.getDefaultConfig());
  }

  public startTimer(): void {
    this.stopTimer();
    this.setTimer();
  }

  public stopTimer(): void {
    if(this.timer){
      clearTimeout(this.timer);
    }
  }

  public setComponent(component: any): void {
    this.setNewComponent(component);
  }

  public setBoundary(boundary: HTMLElement): void {
    this.boundary = boundary;
  }

  public destroyPopover(id?: string): void {
    if(this.popoverId && (this.popoverId != (id ?? null))){
      this._destroyPopover();
    }
  }

  public createOverflowMiddleware(): Middleware | Error {
    const boundaryElement: HTMLElement | null   = this.boundary;

    if(!boundaryElement){
      throw new Error('Boundary element not found');
    }

    return {
      name: 'overflow',
      async fn(state: MiddlewareState): Promise<MiddlewareReturn> {
        return await detectOverflow(state, { boundary: boundaryElement!}) as MiddlewareReturn;
      }
    }
         
  }

  protected calculatePlacement(element: HTMLElement | MapViewerNode): Placement {
    return 'top'
  }

  // PRIVATE METHODS
  private createNewPopover(
    elementReference: HTMLElement | MapViewerNode,
    cssClass?: string | string[],
    options?: Partial<ComputePositionConfig>
  ): Observable<ComputePositionReturn> {
    
    return this.calculatePosition(elementReference as HTMLElement, options ?? this.getDefaultConfig()).pipe(
      tap(() => this.setService(this)),
      tap(() => this.applyStyles(cssClass)),
      tap(() => this.showPopover())
    )
   
  }

  private setNewComponent(component: ComponentRef<any>): void {
    this.component = this.popoverInstance.popoverComponent!.content.createComponent<any>(component as any);
  }

  private showPopover(): void {
    this.popoverInstance.popover?.removeAttribute('hidden');
  }

  private _destroyPopover(): void {
    
    // Eliminamos el componente montado ( si existe )
    if(this.component){
      this.component.destroy();
    }
    
    // Resetear el popover
    this.popoverId = null;
    this.popoverInstance.popoverComponent!.cssClass = '';
    this.popoverInstance.popover?.setAttribute('hidden', 'true');
  }

  private setService(service: PopoverService): void {
    this.popoverInstance.popoverComponent!.setService(service); 
  }

  private applyStyles(cssClass?: string | string[]): void {
    cssClass ? this.popoverInstance.popoverComponent!.cssClass = cssClass : null;
  }
  
  private setTimer(): void {
    this.timer = setTimeout((): void => {
      this._destroyPopover();
    }, this.time);
  }

  /**
   * Retorna el objeto de configuración por defecto del Popover.
   * Como un middleware (arrow) hace referencia a un elemento que se instancia en el DOM
   * se debe esperar a que el componente se haya renderizado para poder acceder a dicho elemento.
   * 
   * @returns {ComputePositionConfig} Configuración por defecto del Popover
   */
  private getDefaultConfig(): ComputePositionConfig {

     // Configuración de posición 
    // https://floating-ui.com/docs/middleware
    return {
      middleware: [
      
        // Distancia del popover al elemento
        offset(5),
  
        // Auto Flip 
        flip({
          fallbackPlacements: ['right', 'bottom'],
        }),
  
        // Arrow Middleware
        arrow({element: this.popoverInstance.popoverArrow as HTMLElement}),
        
      ],
      placement: 'top',
    }
    
  }

  /**
   * Retorna un Observable que al suscribirse, calcula la posición del Popover.
   * @param   {HTMLElement} elementReference 
   * @param   {ComputePositionConfig} options 
   * @returns {Observable<ComputePositionReturn>} Poisición del Popover
   */
  private calculatePosition(elementReference: HTMLElement, options?: Partial<ComputePositionConfig>): Observable<ComputePositionReturn> {
    return from(computePosition(elementReference, this.popoverInstance.popover as HTMLElement, options)).pipe(
      tap((position: ComputePositionReturn) => this.applyPosition(position))
    );
  }

  private applyPosition(position: ComputePositionReturn): void {
    
    const {x, y} = position,
          {x: xArrow, y: yArrow} = position.middlewareData.arrow ?? {x: 0, y: 0};

    Object.assign(this.popoverInstance.popover!.style, {
      'top':  `${y}px`,
      'left': `${x}px`,
    })

    Object.assign(this.popoverInstance.popoverArrow!.style, {
      top:  yArrow != null ? `${yArrow}px` : '',
      left: xArrow != null ? `${xArrow}px` : '',
    });

  }

}
