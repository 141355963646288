<app-form
    [title]="title"
    [showButtons]="userData ? true : false"
    [form]="personalDataForm"
    (submit)="submitForm()"
    (dismiss)="dismissChanges()"
>    
    
    <form form id="personalData" [formGroup]="personalDataForm" (ngSubmit)="submitForm()" class="form">
        
        <!-- SUBTITLE DESKTOP -->
        <p class="hidden md:block sm:text-xs md:text-sm text-darkgray -translate-y-4">
            {{ subtitle }}
        </p>

        <!-- PERSONAL DATA -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-2 gap-4">

            <!-- NAME -->
            <div class="flex flex-col">
                
                <label class="text-main font-semibold font-main mb-1" for="firstName">
                    First Name
                </label>
                <input class="input" type="text" formControlName="firstName" id="firstName" />
                
                <!-- ERRORS -->
                <ng-container *ngFor="let fieldError of formControls.firstName.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.firstName.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>

            </div>

            <!-- LAST NAME -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="lastName">
                    Last Name
                </label>
                <input type="text" formControlName="lastName" id="lastName" />

                <!-- ERRORS -->
                <ng-container *ngFor="let fieldError of formControls.lastName.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.lastName.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>

            </div>

            <!-- GENDER -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="gender">
                    Gender
                </label>
                <select name="gender" id="gender" formControlName="gender">
                    <option value="" disabled selected>Please select one</option>
                    <option value="MALE">
                        Male
                    </option>
                    <option value="FEMALE">
                        Female
                    </option>
                    <option value="NOT_LISTED">
                        Not Listed
                    </option>
                    <option value="NOT_ANSWER">
                        Prefer not to answer
                    </option>
                </select>

                <ng-container *ngFor="let fieldError of formControls.gender.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.gender.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>
            </div>

            <!-- BIRTH DATE -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="birthDate">
                    Date of Birth
                </label>
                <input type="date" formControlName="birthDate" id="birthDate" />

                <ng-container *ngFor="let fieldError of formControls.birthDate.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.birthDate.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        Invalid date
                    </span>
                </ng-container>
            </div>

            <!-- PHONE NUMBER -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="phoneNumber">
                    Primary Phone Number
                </label>
                <input type="number" formControlName="phoneNumber" id="phoneNumber" />

                <ng-container *ngFor="let fieldError of formControls.phoneNumber.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.phoneNumber.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>
            </div>

        </div>

        <!-- ADRESS DATA -->
        <div class="grid grid-cols-1 lg:grid-cols-3 grid-rows-2 gap-4 mt-5">

            <!-- ADRESS LINE 1 -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="address">
                    Address Line 1
                </label>    
                <input type="text" formControlName="address" id="address" />
                
                <!-- ERRORS -->
                <ng-container *ngFor="let fieldError of formControls.address.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.address.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>
            </div>

            <!-- ADRESS LINE 2 -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="address2">
                    Address Line 2
                </label>
                <input type="text" formControlName="address2" id="address2" />
            </div>

            <!-- ZIP CODE -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="zipCode">
                    Postal Code (ZIP)
                </label>
                <input type="text" formControlName="zipCode" id="zipCode" />
                
                <!-- ERRORS -->
                <ng-container *ngFor="let fieldError of formControls.zipCode.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.zipCode.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>
            </div>

            <!-- COUNTRY -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="country">
                    Country
                </label>
                <select class="countrySelector" formControlName="country" name="country" id="country">
                    <option *ngFor="let country of countryData.countries" [value]="country.icaoCode">
                        {{ country.name }}
                    </option>
                </select>

                <!-- ERRORS -->
                <ng-container *ngFor="let fieldError of formControls.country.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.country.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>
            </div>

            <!-- STATE -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="state">
                    State / Province
                </label>
                
                <ng-container *ngIf="!countryData.hasSubcountries else subcountrySelector">
                    <input type="text" formControlName="state" name="state" id="state"/>
                </ng-container>
                <ng-template #subcountrySelector>
                    <select class="countrySelector" formControlName="state" name="state" id="state" >
                        <option value="" disabled selected>Please select one</option>
                        <option *ngFor="let subcountry of countryData.availableSubcountries" [value]="subcountry.sub_country_code">
                            {{ subcountry.sub_country_name }}
                        </option>
                    </select>
                </ng-template>

                <!-- ERRORS -->
                <ng-container *ngFor="let fieldError of formControls.state.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.state.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>

            </div>

            <!-- CITY -->
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="city">
                    City
                </label>
                <input type="text" formControlName="city" id="city"/>

                <!-- ERRORS -->
                <ng-container *ngFor="let fieldError of formControls.city.errors! ?? {} | keyvalue">
                    <span *ngIf="formControls.city.touched" class="text-sm md:text-xs font-main text-secondary font-medium ml-1 opacity-80">
                        {{ errors[fieldError.key] }}
                    </span>
                </ng-container>
            </div>

        </div>

    </form>
    

</app-form>
