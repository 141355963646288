import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from './popover.component';
import { DvmPopoverComponent } from './partials/dvm-popover/dvm-popover.component';
import { LogoModule } from "../logo/logo.module";



@NgModule({
  declarations: [
    PopoverComponent,
    DvmPopoverComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PopoverComponent
  ],
})
export class PopoverModule { }
