import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalDataComponent } from './personal-data.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PersonalDataFormComponent } from './personal-data-form/personal-data-form.component';
import { PersonalDataAccountComponent } from './personal-data-account/personal-data-account.component';
import { PersonalDataProtectionComponent } from './personal-data-protection/personal-data-protection.component';
import { FormModule } from "../../shared/components/globals/form/form.module";

@NgModule({
    declarations: [
        PersonalDataComponent,
        PersonalDataFormComponent,
        PersonalDataAccountComponent,
        PersonalDataProtectionComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormModule
    ],
    exports: [
        PersonalDataComponent
    ]
})
export class PersonalDataModule { }