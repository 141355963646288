<div class="px-3 lg:py-5 lg:px-0 lg:max-w-4xl xl:max-w-6xl mx-auto">

    <!-- TITLE -->
    <h4 class="text-secondary text-2xl my-5">
        {{ title }}
    </h4>

    <!-- FORM -->
    <ng-content select="[form]"/>

    <!-- ACTION BUTTONS -->
    <ng-container *ngIf="showButtons">

        <!-- EDIT , DISMISS AND SAVE BUTTONS -->
        <div class="flex justify-between mb-5 mt-10">
            
            <!-- EDIT BUTTON -->
            <div class="w-1/6 lg:w-2/12">
                <button class="button " [disabled]="canEdit()" (click)="toggleEdit(true)" [ngClass]="{'opacity-0': hideEdit}">
                    {{templateVars.edit}}
                </button>
            </div>
        
            <!-- DISMISS AND SAVE BUTTONS -->
            <div class="flex w-4/6 lg:w-4/12 gap-x-3">
                
                <button class="button " [disabled]="!canEdit()" (click)="onDismiss()" >
                    {{templateVars.dismiss}}
                </button>
                
                <button class="button" [formTarget]="form"  [disabled]="!_canSubmit" type="submit" (click)="onSubmit()">
                    {{templateVars.submit}}
                </button>

            </div>
    
        </div>

    </ng-container>

</div>
