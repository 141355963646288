import { MapViewerInputNode, MapViewerNode, Viewer3dLoadView3dOptionsBasic } from '@3ddv/dvm-internal';
import { Component, Inject, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DvmPopoverService } from 'src/app/core/services/override/popovers/dvm-popover.service';
import { PopoverComponent } from '../../popover.component';
import { PopoverService } from 'src/app/core/services/popover.service';
import { DvmService } from 'src/app/core/services/dvm.service';

@Component({
  selector: 'app-dvm-popover',
  templateUrl: './dvm-popover.component.html',
  styleUrl: './dvm-popover.component.css',
  providers: [
    {provide: PopoverService, useClass: DvmPopoverService}
  ]
})
export class DvmPopoverComponent implements OnChanges {

  // INPUTS
  @Input({required: true})
  public mapNode: MapViewerNode | null = null;

  // SERVICES
  private dvm: DvmService = inject(DvmService);

  // DATA
  public thumbnail: HTMLImageElement | null = null;

  // GETTERS
  public get textLabel(): string {
    if(!this.mapNode) return '';

    switch(this.mapNode.type){
      case 'section':
        return "Section " + this.mapNode.original_id.split('_')[1];
      case 'seat':
        const data = this.mapNode.original_id.split('_');
        return data[0] ?? '';
        
      default:
        return '';
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    
   if(changes['mapNode']){
    this.setThumbnail(this.mapNode!);
   }

  }

  public openView(): void {
    console.log('Open View:', this.mapNode);
   if(this.mapNode?.type === 'seat'){
    return;
   }

   const options: Viewer3dLoadView3dOptionsBasic = {
      venue_id: this.dvm.viewer.getVenueId()!,
      view_id: this.mapNode!.id,
   }

   this.dvm.viewer3d.loadView3d(options);
   this.dvm.viewer3d.toggleFullscreen();
   
  }

  private setThumbnail(node: MapViewerNode): void {
    console.log('Node:', node);
    const options: Viewer3dLoadView3dOptionsBasic = {
      venue_id: this.dvm.viewer.getVenueId()!,
      view_id:  node.id,
    }

    this.dvm.viewer.getThumbnail(options).subscribe((thumbnail: HTMLImageElement) => {
      this.thumbnail = thumbnail;
    });
  }

}
