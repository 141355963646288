<div class="w-full h-full flex flex-col items-center">

    <!-- THUMBNAIL VIEWER -->
    <div class="w-full h-3/4">

        <!-- LOADER -->
        <div class="w-full flex h-full relative">
            
            <div class="bg-black w-full h-full absolute z-10  transition-opacity duration-500 pointer-events-none" [ngClass]="{ 'opacity-0': thumbnail != null }">
                <span></span>
            </div>

            <ng-container *ngIf="thumbnail">
                <img [src]="thumbnail.src" alt="thumbnail" class="w-full h-full object-cover" (click)="openView()" />
            </ng-container>

        </div>

    </div>

     <!-- TEXT LABEL -->
     <div class="h-1/4 w-full flex items-center justify-center">
        <p class="text-white text-sm xl:text-base">{{ textLabel }}</p>
    </div>


</div>